<template>
  <b-row class="center-aligning-row verify-email">
    <b-col cols="12" md="8" lg="5">
      <b-spinner v-if="isVerifying" />
      <div v-else-if="!hasError && !isVerifying">
        <h2>{{ $t("verifyEmail.successText") }}</h2>
        <b-button to="/">{{ $t("general.logIn") }}</b-button>
      </div>
      <div v-else>
        <h2>{{ $t("errors.cantGetData") }}</h2>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import MainAlert from "../components/alerts/MainAlert.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Verify-email",
  data() {
    return {
      isVerifying: true,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({ authUrl: "getRestAuthUrl" }),
  },
  mounted() {
    axios
      .post(`${this.authUrl}/registration/verify-email/`, {
        key: this.$route.params.key,
      })
      .then(() =>
        this.$toast.success({
          component: MainAlert,
          props: {
            message: this.$i18n.t("verifyEmail.successAlert"),
          },
        })
      )
      .catch(() => {
        this.hasError = true;
        this.$toast.error({
          component: MainAlert,
          props: {
            message: this.$i18n.t("verifyEmail.errorAlert"),
          },
        });
      })
      .finally(() => (this.isVerifying = false));
  },
};
</script>
<style lang="scss" >
.verify-email .btn {
  display: block;
  margin: 0 auto;
  max-width: toRem(100);
}
</style>